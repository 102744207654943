'use client';

import type { ReactNode } from 'react';

interface IProps {
  as?: 'button' | 'span';
  buttonType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: (
    _e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => void;
  disabled?: boolean;
  styleType?: 'pink' | 'purple' | 'blue' | 'gray' | 'pink-white' | 'yellow';
  size?: 'default' | 'small';
  children: ReactNode;
  className?: string;
  width?: string;
}

export function Button({
  as = 'button',
  styleType = 'pink',
  size = 'default',
  onClick,
  className,
  disabled,
  children,
  buttonType,
  ...rest
}: IProps &
  React.ButtonHTMLAttributes<HTMLButtonElement> &
  React.AnchorHTMLAttributes<HTMLAnchorElement>) {
  const getButtonClass = () => {
    let buttonClass = '';
    const sizeClass = size === 'small' ? 'p-2 text-sm font-semibold' : 'p-6';

    if (disabled) {
      buttonClass = 'bg-page-subcolor04 text-white cursor-default';
      return `${buttonClass} ${sizeClass}`;
    }
    switch (styleType) {
      case 'pink':
        buttonClass = 'text-white  bg-page-subcolor02';
        break;
      case 'purple':
        buttonClass = 'text-white  bg-page-subcolor01';
        break;
      case 'blue':
        buttonClass = 'text-white  bg-page-subcolor03';
        break;
      case 'gray':
        buttonClass = disabled
          ? 'bg-page-subcolor04 text-white cursor-default'
          : 'bg-page-subcolor04 text-page-black';
        break;
      case 'pink-white':
        buttonClass =
          'border border-page-subcolor02 text-page-subcolor02 bg-white';
        break;
      case 'yellow':
        buttonClass =
          'text-black  bg-page-subcolor18 border-page-subcolor19 border';
        break;
      default:
        break;
    }

    return `${buttonClass} ${sizeClass}`;
  };

  const buttonClass = getButtonClass();
  const buttonCommonClass = ` ${buttonClass} w-full inline-block text-center rounded-full leading-tight ${className}`;

  if (as === 'span') {
    return (
      <span className={buttonCommonClass} {...rest}>
        {children}
      </span>
    );
  }

  return (
    <button
      className={buttonCommonClass}
      type={buttonType === 'button' ? 'button' : 'submit'}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
}
